import Layout from "../layout";
import Template from "../template";

import Index from "../views/index";

export default [
   {
      path: "/",
      redirect: "/index",
   },
   {
      path: "/index",
      component: Layout,
      children: [
         {
            path: "",
            name: "index",
            component: Index,
         },
      ],
   },
   {
      path: "/lists",
      component: Layout,
      children: [
         {
            path: "",
            name: "lists",
            component: () =>
               import(/* webpackChunkName: "page-map" */ "../views/lists"),
            children: [
               {
                  path: "",
                  name: "lists",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-company" */ "../views/lists/children/index"
                     ),
               },
            ],
         },
      ],
   },
   {
      path: "/map",
      component: Layout,
      children: [
         {
            path: "",
            name: "map",
            component: () =>
               import(/* webpackChunkName: "page-map" */ "../views/map"),
         },
      ],
   },
   {
      path: "/company",
      component: Layout,
      children: [
         {
            path: "",
            name: "company",
            redirect: "introduce",
            component: () =>
               import(
                  /* webpackChunkName: "page-company" */ "../views/company"
               ),

            children: [
               {
                  path: "introduce",
                  name: "introduce",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-company" */ "../views/company/children/introduce"
                     ),
               },
               {
                  path: "leader",
                  name: "leader",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-company" */ "../views/company/children/leader"
                     ),
               },
               {
                  path: "organization",
                  name: "organization",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-company" */ "../views/company/children/organization"
                     ),
               },
               {
                  path: "develop",
                  name: "develop",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-company" */ "../views/company/children/develop"
                     ),
               },
               {
                  path: "milestone",
                  name: "milestone",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-company" */ "../views/company/children/milestone"
                     ),
               },
               {
                  path: "culture",
                  name: "culture",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-company" */ "../views/company/children/culture"
                     ),
               },
            ],
         },
      ],
   },
   {
      path: "/party",
      component: Layout,
      children: [
         {
            path: "",
            name: "party",
            component: () =>
               import(/* webpackChunkName: "page-party" */ "../views/party"),
         },
      ],
   },
   {
      path: "/duty",
      component: Layout,
      children: [
         {
            path: "",
            name: "duty",
            component: () =>
               import(/* webpackChunkName: "page-duty" */ "../views/duty"),
         },
      ],
   },
   {
      path: "/tobacco",
      component: Layout,
      children: [
         {
            path: "",
            name: "tobacco",
            redirect: "classroom",
            component: () =>
               import(
                  /* webpackChunkName: "page-tobacco" */ "../views/tobacco"
               ),
            children: [
               {
                  path: "innovate",
                  name: "innovate",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-tobacco" */ "../views/tobacco/children/innovate"
                     ),
               },
               {
                  path: "investigate",
                  name: "investigate",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-tobacco" */ "../views/tobacco/children/investigate"
                     ),
               },
               {
                  path: "consult",
                  name: "consult",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-tobacco" */ "../views/tobacco/children/consult"
                     ),
               },
               {
                  path: "classroom",
                  name: "classroom",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-tobacco" */ "../views/tobacco/children/classroom"
                     ),
               },
               {
                  path: "wenjuan",
                  name: "WenjuanList",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-tobacco" */ "../views/tobacco/children/wenjuan/list"
                     ),
               },
               {
                  path: "qiye",
                  name: "qiye",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-tobacco" */ "../views/tobacco/children/qiye/index"
                     ),
               },
               {
                  path: "wenjuan/result/:wenjuan_id",
                  name: "WenjuanResult",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-tobacco" */ "../views/tobacco/children/wenjuan/result"
                     ),
               },
               {
                  path: "wenjuan/:wenjuan_id",
                  name: "Wenjuan",
                  component: () =>
                     import(
                        /* webpackChunkName: "page-tobacco" */ "../views/tobacco/children/wenjuan"
                     ),
               },
               // {
               //    path: "identify/:id",
               //    name: "brand",
               //    component: () =>
               //       import(
               //          /* webpackChunkName: "page-tobacco" */ "../views/tobacco/children/brand"
               //       ),
               // },
            ],
         },
      ],
   },
   {
      path: "/article",
      component: Layout,
      children: [
         {
            path: "",
            name: "article",
            component: () =>
               import(
                  /* webpackChunkName: "page-article" */ "../views/article"
               ),
         },
      ],
   },
   {
      path: "/posts",
      component: Layout,
      children: [
         {
            path: "",
            name: "posts",
            component: () =>
               import(/* webpackChunkName: "page-article" */ "../views/posts"),
         },
      ],
   },
   {
      path: "/search",
      component: Layout,
      children: [
         {
            path: ":k",
            name: "search",
            component: () =>
               import(/* webpackChunkName: "page-search" */ "../views/search"),
         },
      ],
   },
   {
      path: "/features",
      component: Layout,
      children: [
         {
            path: "",
            name: "features",
            component: () =>
               import(/* webpackChunkName: "page-map" */ "../views/features"),
         },
         {
            path: "2",
            name: "features2",
            component: () =>
               import(/* webpackChunkName: "page-map" */ "../views/features/2"),
         },
      ],
   },
   {
      path: "/zt",
      component: Template,
      children: [
         {
            path: "chuxin",
            name: "features_chuxin",
            component: () =>
               import(
                  /* webpackChunkName: "page-map" */ "../views/features/chuxin"
               ),
         },
      ],
   },
   {
      path: "/hello2023",
      name: "hello2023",
      component: () =>
         import(/* webpackChunkName: "page-404" */ "../views/hello2023"),
   },
   {
      path: "/*",
      component: Layout,
      children: [
         {
            path: "",
            name: "404",
            component: () =>
               import(/* webpackChunkName: "page-404" */ "../views/404"),
         },
      ],
   },
];
